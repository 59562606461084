<template>
    <a-menu
        mode="inline"
        @click="handleClick"
        :selectedKeys="[$route.path]"
    >
      <a-menu-item v-for="item in menuList" :key="'/'+item.path.split('/')[2]">
        {{ item.title }}
      </a-menu-item>
  </a-menu>
</template>

<script>
export default {
  name: "Menu",
  props:['menuList'],
  data() {
    return {
      InfoCenterMenu: []
    };
  },
  methods: {
    handleClick(e) {
      this.$router.push(e.key)
    },
  }
}
</script>

<style scoped>
.ant-menu-item{
  font-weight: 550;
}
.ant-menu-item-active {
 background-color:rgb(35, 110, 161) !important;
 color: #fff !important;
}
.ant-menu-item-selected{
  background-color: rgb(35, 110, 161) !important;
  color: #fff !important;
}
.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: rgb(35, 110, 161);
  border-bottom: 2px solid rgb(35, 110, 161);
}

.ant-menu-submenu-horizontal .ant-menu-submenu-open .ant-menu-submenu-active {
  color: rgb(35, 110, 161);
  border-bottom: 2px solid rgb(35, 110, 161);
}

.ant-menu-submenu > .ant-menu {
 width: 100px;
 min-width: 100px;
}
</style>
