<template>
  <div class="container">
    <div class="aHeader">{{title}}</div>
    <div class="aTitle" >
      <span>信息来源：{{infoFrom}}</span>
      <span style="margin-left: 50px">发布时间：{{date.split(" ")[0]}}</span>
      <span style="margin-left: 50px">浏览次数：{{llcs}}</span>
    </div>
    <div class="content" v-html="richText">
  </div>

  </div>
</template>

<script>
import {getXxzxRichTextById,postXxzxLlcs,getZyywRichTextById,postZyywLlcs,getDjzxRichTextById,postDjzxLlcs} from "../apis/richText";
export default {
  name: "RichText",
  data(){
    return{
      richText:"",
      title: "",
      date: "",
      infoFrom: '',
      llcs: -1
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getRichText(this.$route.path)
      }
    }
  },
  methods:{
    getRichText(param){
      var path = param.split("/id=")[0];
      var id = param.split("/id=")[1];
      if (path.search("zyyw")>-1){
        getZyywRichTextById({
          id:id
        }).then((res)=>{
          this.handleData(res)
          postZyywLlcs({
            "id": id,
            "mhLlcs": this.llcs +1
          }).then()
        })
      }else if (path.search("infoCenter")>-1){
        getXxzxRichTextById({
          id:id
        }).then((res)=>{
          this.handleData(res)
          postXxzxLlcs({
            "id": id,
            "mhLlcs": this.llcs +1
          }).then()
        })
      }else if (path.search("djzx")>-1){
        getDjzxRichTextById({
          id:id
        }).then((res)=>{
          this.handleData(res)
          postDjzxLlcs({
            "id": id,
            "mhLlcs": this.llcs +1
          }).then()
        })
      }
    },
    handleData(res){
      var data = res.result
      this.richText = data.mhGgnr
      this.title = data.mhGgbt
      this.date = data.createTime
      this.infoFrom = data.mhXxly
      this.llcs = data.mhLlcs
    }
  }
}
</script>

<style scoped>
  .container{
    background: #fff;
    min-height: 650px;
    box-shadow: 0px 0px 2px 4px #6b5b5b14;
    padding: 25px;
  }
  .aHeader{
    color: #00628f;
    line-height: 40px;
    font-size: 22px;
    font-family: 微软雅黑;
    text-align: center;
    padding-bottom:10px;
  }
  .aTitle{
    padding:0 50px;
    padding-bottom:10px;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
  }
  .content{
    margin-top: 30px;
    font-family: "mirco yahei";
  }

  ::v-deep img{
    max-width:100%;
    height: auto;
  }
</style>
