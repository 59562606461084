import {get} from "../utils/request";

export function getDataFromGywm(param){
    return get("/gsmh_gywm/gsmhGywm/listSimple",{
        mhGglx: param.mhGglx,
        mhGgzt: "0",
        pageSize: param.pageSize,
        pageNo: param.pageNo,
        column: param.ORDER_COLUMN,
        order:param.ORDER_TYPE
    })
}
export function getGsDataById(id){
    return get("/gsmh_gywm/gsmhGywm/queryById",{id})
}