//主页接口地址
import { get } from "../utils/request";

export const URL = "http://admin.fjbygs.com/jeecg-boot/sys/common/static/"

export async function  getLBNR(){
    var list = []
    await get("/gsmh_xxtz/gsmhXxtz/list",{
        pageNo: 1,
        pageSize: 20,
        mhGgzt: "0",
        mhSftbsy: 'Y'
    }).then((res)=>{
        list = bianliData(res.result.records,list,"/infoCenter")
    })
    await get("/gsmh_zyyw/gsmhZzyw/list",{
        pageNo: 1,
        pageSize: 20,
        mhGgzt: "0",
        mhSftbsy: 'Y'
    }).then((res)=>{
        list = bianliData(res.result.records,list,"/zyyw")
    })
    await get("/gsmh_djzx/gsmhDjzx/list",{
        pageNo: 1,
        pageSize: 20,
        mhGgzt: "0",
        mhSftbsy: 'Y'
    }).then((res)=>{
        list = bianliData(res.result.records,list,"/djzx")
    })
    return list
}
export function bianliData(res,list,basePath){
    for (var i=0;i<res.length;i++){
        let item = res[i]
        if (basePath=="/infoCenter") {
            switch (item.mhGglx) {
                case "1":
                    item.basePath = basePath + "/xwdt/richText/id=" + item.id
                    break;
                case "0":
                    item.basePath = basePath + "/tzgg/richText/id=" + item.id
                    break;
                case "2":
                    item.basePath = basePath + '/zcfg/richText/id=' + item.id
                    break;
            }
        }else if (basePath=="/zyyw") {
            switch (item.mhGglx) {
                case "0":
                    item.basePath = basePath + "/bzzxd/richText/id=" + item.id
                    break;
                case "1":
                    item.basePath = basePath + "/bzhsdsf/richText/id=" + item.id
                    break;
                case "2":
                    item.basePath = basePath + "/bzssxgpg/richText/id=" + item.id
                    break;
                case "3":
                    item.basePath = basePath + "/bzhxxh/richText/id=" + item.id
                    break;
                case "4":
                    item.basePath = basePath + "/bztxjs/richText/id=" + item.id
                    break;
                case "5":
                    item.basePath = basePath + "/bzhzczd/richText/id=" + item.id
                    break;
                case "6":
                    item.basePath = basePath + "/sptmfw/richText/id=" + item.id
                    break;
                case "7":
                    item.basePath = basePath + "/bzhpx/richText/id=" + item.id
                    break;
                case "8":
                    item.basePath = basePath + "/bsbpsj/richText/id=" + item.id
                    break;
                case "9":
                    item.basePath = basePath + "/bztsxs/richText/id=" + item.id
                    break;
                case "a":
                    item.basePath = basePath + "/hyjzlfw/richText/id=" + item.id
                    break;
                case "b":
                    item.basePath = basePath + "/scjgwh/richText/id=" + item.id
                    break;
            }
        }else if (basePath=="/djzx") {
            switch (item.mhGglx) {
                case "0":
                    item.basePath = basePath + "/djgz/richText/id=" + item.id
                    break;
            }
        }
        list.push(item)
    }
    return list
}

export function getXxtzList(){
    return get("/gsmh_xxtz/gsmhXxtz/list",{
        pageNo: 1,
        pageSize: 5,
        mhGgzt: "0",
        mhSftbsy: 'Y'
    })
}

export function getZzywList(){
    return get("/gsmh_zyyw/gsmhZzyw/list",{
        pageNo: 1,
        pageSize: 5,
        mhGgzt: "0",
        mhSftbsy: 'Y'
    })
}

export function getDjzxList(){
    return get("/gsmh_djzx/gsmhDjzx/list",{
        pageNo: 1,
        pageSize: 5,
        mhGgzt: "0",
        mhSftbsy: 'Y'
    })
}

export function getYWCD(){
    return get("/gsmh_ywcd/gsmhYwcd/list",{
        pageNo: 1,
        pageSize: 20,
        column: ["mhSh"],
        order: ["asc"]
    })
}
export function getJCAL(){
    return get("/gsmh_zyyw/gsmhZzyw/list",{
        pageNo: 1,
        pageSize: 20,
        mhJcal: 'Y'
    })
}
export function getDLBT(){
    return get("/gsmh_dlb/gsmhDlb/list",{
        pageNo: 1,
        pageSize: 20,
        column: "mhPx",
        order: "asc"
    })
}
