<template>
  <a-breadcrumb class="crumb" separator=">" >
    <a-breadcrumb-item v-for="(item, index) in breadList" :key="item.name">
      <router-link
          v-if="item.name != name && index != 1"
          :to="{ path: item.path === '' ? '/' : item.path }"
      >{{ item.meta.title }}</router-link>
      <span v-else>{{ item.meta.title }}</span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: "BreadCrumb",
  data () {
    return {
      name: '',
      breadList: []
    }
  },
  created () {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb () {
      this.breadList = []
      this.name = this.$route.name
      this.$route.matched.forEach(item => {
        this.breadList.push(item)
      })
    }
  },
  watch: {
    $route () {
      this.getBreadcrumb()
    }
  }
}
</script>

<style scoped>
.crumb{
  line-height: 30px;
  height: 30px;
  background: #fff;
  margin-bottom: 15px;
  padding-left: 5px;
}
</style>