import axios from 'axios'
import { notification } from 'ant-design-vue'
import store from '../store/index'
let apiBaseUrl = "https://admin.fjbygs.com/jeecg-boot"
// let apiBaseUrl = "http://admin.fjbygs.com/jeecg-boot"
//let apiBaseUrl = "http://localhost:8080/jeecg-boot"
const service = axios.create({
    baseURL: apiBaseUrl, // api base_url
    timeout: null // 请求超时时间
});

const err = (error) => {
    if (error.response) {
        let data = error.response.data

        switch (error.response.status) {
            case 403:
                notification.error({ message: '系统提示', description: '拒绝访问',duration: 4})
                break
            case 404:
                notification.error({ message: '系统提示', description:'很抱歉，资源未找到!',duration: 4})
                break
            case 504:
                notification.error({ message: '系统提示', description: '网络超时'})
                break
            default:
                notification.error({
                    message: '系统提示',
                    description: data.message,
                    duration: 4
                })
                break
        }
    }
    return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
    store.dispatch('doChangeLoadingStatus')
    return config
},(error) => {
    return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
    store.dispatch('doChangeLoadingStatus')
    return response.data
}, err);
export function get(url,params){
    return service.get(url, {
        params
    });
}
export function post(url,data){
    return service.post(url,data);
}
export function put(url,data){
    return service.put(url,data);
}
