import {get} from "../utils/request";

export function getDataFromDjzx(param){
    return get("/gsmh_djzx/gsmhDjzx/list",{
        mhGglx: param.mhGglx,
        mhGgzt: "0",
        mhGgbt: param.mhGgbt,
        pageSize: param.pageSize,
        pageNo: param.pageNo,
        column: param.ORDER_COLUMN,
        order:param.ORDER_TYPE
    })
}
