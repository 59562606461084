<template>
  <div>
    <a-row :gutter="16" v-if="$route.meta.title!='详情内容'">
      <a-col :span="5">
        <Menu :menuList="menuList"></Menu>
      </a-col>
      <a-col :span="19" v-if="$route.matched[0].path!=='/gywm'">
        <a-row>
          <a-input-search
              style="margin-bottom: 12px"
              placeholder="请输入搜索标题"
              enter-button="搜索"
              size="large"
              @search="onSearch"
          />
        </a-row>
        <a-row>
          <List @getDataByUrl="getDataByUrl" :result="result" v-if="result"></List>
        </a-row>
      </a-col>
      <a-col :span="19" v-else>
        <DetaiInfoPage :data="result" v-if="result"></DetaiInfoPage>
      </a-col>
    </a-row>
    <router-view />
  </div>


</template>

<script>
import List from './component/List.vue'
import Menu from './component/Menu.vue'
import DetaiInfoPage from '@/components/AboutUs/DetaiInfoPage.vue'
import { getDataFromTzgg,getDataFromXwdt,getDataFromZcfg } from "../../apis/InfoCenterApi";
import { getGsDataById } from "../../apis/GywmApi";
import { getDataFromDjzx } from "../../apis/DjzxApi";
import { getDataFromZyyw } from "../../apis/ZyywApi";
export default {
  name: "InfoList",
  components: {
    List,
    Menu,
    DetaiInfoPage
  },
  data(){
    return{
      result: null,
      searchContent: "",
      ORDER_TYPE: "desc",
      ORDER_COLUMN: "create_time",
      // 菜单栏数组
      menuList:[]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.path=="/fjbyxxgs"){
          this.getDataByUrl({
            id:'1438795117252202498',
            path: this.$route.path
          });
        }else if (this.$route.path=="/fjxbzwhgs"){
          this.getDataByUrl({
            id:'1438796011096133634',
            path: this.$route.path
          });
        }else{
          this.getDataByUrl({
            mhGgbt: this.searchContent,
            pageSize:10,
            pageNo:1,
            path: this.$route.path
          });
        }
        
      }
    }
  },
  methods:{
    // 获取列表数据
    getDataByUrl(param){
      let pageType = this.$route.matched[0].path
      let path = param.path
      if(pageType=="/infoCenter"){
        // 信息中心
        if (path.search("/xwdt")!=-1){
        getDataFromXwdt({
            mhGgbt: this.searchContent,
            pageSize:param.pageSize,
            pageNo: param.pageNo,
            ORDER_COLUMN: this.ORDER_COLUMN,
            ORDER_TYPE: this.ORDER_TYPE
          }).then((res)=>this.handleData(res))
        }else if (path.search("/tzgg")!=-1){
          getDataFromTzgg({
            mhGgbt: this.searchContent,
            pageSize:param.pageSize,
            pageNo: param.pageNo,
            ORDER_COLUMN: this.ORDER_COLUMN,
            ORDER_TYPE: this.ORDER_TYPE
          }).then((res)=>this.handleData(res))
        }else if (path.search("/zcfg")!=-1){
          getDataFromZcfg({
            mhGgbt: this.searchContent,
            pageSize:param.pageSize,
            pageNo: param.pageNo,
            ORDER_COLUMN: this.ORDER_COLUMN,
            ORDER_TYPE: this.ORDER_TYPE
          }).then((res)=>this.handleData(res))
        }
      }else if(pageType=="/zyyw"){
        // 主营业务
        let type;
        switch (path){
          case "/bzzxd":
            type = "0";break;
          case "/bzhsdsf":
            type = "1";break;
          case "/bzssxgpg":
            type = "2";break;
          case "/bzhxxh":
            type = "3";break;
          case "/bztxjs":
            type = "4";break;
          case "/bzhzczd":
            type = "5";break;
          case "/sptmfw":
            type = "6";break;
          case "/bzhpx":
            type = "7";break;
          case "/bsbpsj":
            type = "8";break;
          case "/bztsxs":
            type = "9";break;
          case "/hyjzlfw":
            type = "a";break;
          default:
            type = "b";
        }
        getDataFromZyyw({
          mhGgbt: this.searchContent,
          mhGglx: type,
          pageSize:param.pageSize,
          pageNo: param.pageNo,
          ORDER_COLUMN: this.ORDER_COLUMN,
          ORDER_TYPE: this.ORDER_TYPE
        }).then((res)=>{
          this.handleData(res)
        })
      }else if(pageType=="/djzx"){
        // 党建中心
        getDataFromDjzx({
          mhGglx: "0",
          mhGgbt: this.searchContent,
          pageSize:param.pageSize,
          pageNo: param.pageNo,
          ORDER_COLUMN: this.ORDER_COLUMN,
          ORDER_TYPE: this.ORDER_TYPE
        }).then((res)=>{
          this.handleData(res)
        })
      }else if(pageType=="/gywm"){
        // 关于我们
        getGsDataById(param.id).then((res)=>{
          this.handleData(res)
        })
      }
    },
    handleData(res){
      this.result = res.result
    },
    onSearch(value){
      this.searchContent = value
      this.getDataByUrl({
        pageNo:1,
        pageSize: 10,
        path: this.$route.path
      })
    },
    // 获取menu菜单项
    getMenuList(){
      let baseUrl = "/api"
      let matchPath = this.$route.matched[0].path
      if(matchPath=="/infoCenter"){
        // 信息中心
        baseUrl += '/InfoCenterMenu'
      }else if(matchPath=="/zyyw"){
        // 主营业务
        baseUrl += '/MainBusinessMenu'
      }else if(matchPath=="/djzx"){
        // 党建中心
        baseUrl += '/PartyBuildingCenter'
      }else if(matchPath=="/gywm"){
        // 关于我们
        baseUrl += '/AboutUsMenu'
      }
      this.axios.get(baseUrl).then((response)=>{
        this.menuList = response.data.data
      }).catch((response)=>{
        console.log(response);
      })
    }
  },
  mounted(){
    if (this.$route.path !== "/fjbyxxgs" && this.$route.path !== "/fjxbzwhgs") {
      this.getDataByUrl({
        pageSize:10,
        pageNo:1,
        path: this.$route.path
      });
    }
    this.getMenuList()
  }
}
</script>

<style scoped>

</style>
