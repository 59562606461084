<template>
  <div>
    <a-layout >
      <a-row :gutter="16" >
        <a-col :span="11">
          <Carousel :screenWidth="screenWidth" :lbnr="lbnr" />
        </a-col>
        <a-col :span="13">
          <MutiCard :screenWidth="screenWidth" @getDataByMenu="getDataByMenu" :info="info"  />
        </a-col>
      </a-row>
      <Divider :data="['Main Business','主营业务']"></Divider>
      <a-row type="flex" justify="center"  :gutter="25" :style=" {'margin': '12px 0px','max-height': '150px','margin-left': (ywcsSpan==6?'2%':'3%')}">
        <a-col :span="24">
          <a-carousel dot-position="right" :dots="false" autoplay>
            <a-row :gutter="16" v-for="(item_list,index_list) in menuList" :key="index_list">
              <a-col :span="ywcsSpan" v-for="(item,index) in item_list" :key="index" >
                <SingleCard :item="item"></SingleCard>
              </a-col>
            </a-row>
          </a-carousel>
        </a-col>
      </a-row>
      <Divider :data="['Classic Cases','典型案例']"></Divider>
      <a-row  :style="{'margin-top': '23px','margin-left':(jcalSpan==6?'0.5%':'2%')}" :gutter="16" v-for="(item_list,index_list) in jcalList" :key="index_list">
        <a-col v-for="(item,index) in item_list" :key="index" :span="jcalSpan">
          <PicCard :item="item"></PicCard>
        </a-col>
      </a-row>
      <Divider :data="['Links','友情链接']"></Divider>
      <Link></Link>
      
    </a-layout>
  </div>
</template>

<script>
import xxzx from '@/assets/xxzx.jpg';
import zyyw from '@/assets/zyyw.jpg';
import djzx from '@/assets/djzx.jpg';
import gywm from '@/assets/gywm.jpg';
import { URL,getLBNR,getYWCD,getJCAL } from "@/apis/homeApi";
import Divider from '@/components/Home/component/Divider.vue'
import SingleCard from '@/components/Home/component/SingleCard.vue'
import MutiCard from '@/components/Home/component/MutiCard.vue'
import Carousel from '@/components/Home/component/Carousel.vue'
import PicCard from '@/components/Home/component/PicCard.vue'
// import Icon from './component/Icon.vue'
import Link from '@/components/Home/component/link.vue'
import {getDataFromTzgg, getDataFromXwdt, getDataFromZcfg} from "@/apis/InfoCenterApi";
export default {
  name: "HomePage",
  components:{
    Carousel,
    MutiCard,
    SingleCard,
    Divider,
    PicCard,
    // Icon,
    Link
  },
  data(){
    return{
      imgUrl:null,
      lbnr: [],
      info: [],
      jcalList: [],
      jcalSpan: 6,
      ywcsSpan: 6,
      URL: URL,
      menuList: null,
      ORDER_TYPE: "desc,desc",
      ORDER_COLUMN: "create_time",
      screenWidth: document.body.clientWidth
    }
  },
  methods:{
    async getLBNR(){
      this.lbnr = await getLBNR()
    },
    getDataJCAL(param){
      getJCAL().then((res)=>{
        this.jcalList = this.formatArray(res.result.records,param)
      })
    },
    getDataByMenu(type){
      if (type=="xwdt"){
        getDataFromXwdt({
          pageSize:6,
          pageNo: 1,
          ORDER_COLUMN: this.ORDER_COLUMN,
          ORDER_TYPE: this.ORDER_TYPE
        }).then((res)=>this.handleData(res))
      }else if (type=="tzgg"){
        getDataFromTzgg({
          pageSize:6,
          pageNo: 1,
          ORDER_COLUMN: this.ORDER_COLUMN,
          ORDER_TYPE: this.ORDER_TYPE
        }).then((res)=>this.handleData(res))
      }else {
        getDataFromZcfg({
          pageSize:6,
          pageNo: 1,
          ORDER_COLUMN: this.ORDER_COLUMN,
          ORDER_TYPE: this.ORDER_TYPE
        }).then((res)=>this.handleData(res))
      }
    },
    getYwcd(param){
      getYWCD().then((res)=>{
        console.log(123);
        var dataList = this.formatArray(res.result.records,param)
        // 将倒数第一的市场监管文化项和第一的标准制修订调换位置，达到居中效果（懒的改后台）20231031
        if (dataList.length>2) {
          var newArr = dataList[2]
          var ele = newArr.pop()
          newArr.unshift(ele)
          dataList[2] = newArr
        }
        this.menuList = dataList
      })
    },
    handleData(res){
      this.info = res.result.records
    },
    formatArray(data, num) {
      var dataList = []
      for (var i = 0, len = data.length; i < len; i += num) {
        dataList.push(data.slice(i, i + num))
      }
      return dataList
    }
  },
  watch:{
    $route: {
      immediate: true,
      handler() {
        if (this.$route.path!='/'){
          if (this.$route.path.search('infoCenter')>=0){
            this.imgUrl = xxzx
          }else if(this.$route.path.search('zyyw')>=0){
            this.imgUrl = zyyw
          }else if(this.$route.path.search('djzx')>=0){
            this.imgUrl = djzx
          }else if(this.$route.path.search('gywm')>=0){
            this.imgUrl = gywm
          }else {
            this.imgUrl = null
          }
        }else {
          this.imgUrl = null
        }
      }
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      })
    }
    this.getLBNR();
    this.getDataByMenu("xwdt");
    if (this.screenWidth<=1790){
      this.ywcsSpan = 8
      this.getYwcd(3);
    }else {
      this.ywcsSpan = 6
      this.getYwcd(4);
    }
    if (this.screenWidth<=1790){
      this.jcalSpan = 8
      this.getDataJCAL(3);
    }else {
      this.jcalSpan = 6
      this.getDataJCAL(4);
    }
    // window.onload = function(){
    //    setTimeout(() => {
    //       window.scrollTo({top:500,behavior: "smooth"});
    //      //document.getElementById("homePage").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    //    },1000)
    // }
  }
}
</script>

<style scoped>

</style>