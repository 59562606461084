import {get, put} from "../utils/request";


export function getXxzxRichTextById(param){
    return get("/gsmh_xxtz/gsmhXxtz/queryById",param)
}
export function postXxzxLlcs(param){
    return put("/gsmh_xxtz/gsmhXxtz/edit",param)
}

export function getZyywRichTextById(param){
    return get("/gsmh_zyyw/gsmhZzyw/queryById",param)
}
export function postZyywLlcs(param){
    return put("/gsmh_zyyw/gsmhZzyw/edit",param)
}

export function getDjzxRichTextById(param){
    return get("/gsmh_djzx/gsmhDjzx/queryById",param)
}
export function postDjzxLlcs(param){
    return put("/gsmh_djzx/gsmhDjzx/edit",param)
}
