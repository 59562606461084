<template>
  <div  class="container">
    <div class="aHeader">{{data.mhGgbt}}</div>
    <div class="aTitle" ></div>
    <div class="content" v-html="data.mhGgnr">
  </div>

  </div>
</template>

<script>
export default {
  name: "RichText",
  props:{
    data: Object
  },
  beforeMount(){
    console.log(this.data);
  }
}
</script>

<style scoped>
  .container{
    background: #fff;
    min-height: 650px;
    box-shadow: 0px 0px 2px 4px #6b5b5b14;
    padding: 25px;
  }
  .aHeader{
    color: #00628f;
    line-height: 40px;
    font-size: 22px;
    font-family: 微软雅黑;
    text-align: center;
    padding-bottom:10px;
  }
  .aTitle{
    padding:0 50px;
    padding-bottom:10px;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
  }
  .content{
    margin-top: 30px
  }
  ::v-deep img{
    max-width:100%;
    height: auto;
  }
</style>
