import {get} from "../utils/request";

export function getDataFromXwdt(param){
    return get("/gsmh_xxtz/gsmhXxtz/list",{
        mhGglx: "1",
        mhGgzt: "0",
        mhGgbt: param.mhGgbt,
        pageSize: param.pageSize,
        pageNo: param.pageNo,
        column: param.ORDER_COLUMN,
        order:param.ORDER_TYPE
    })
}
export function getDataFromTzgg(param){
    return get("/gsmh_xxtz/gsmhXxtz/list",{
        mhGglx: "0",
        mhGgzt: "0",
        mhGgbt: param.mhGgbt,
        pageSize: param.pageSize,
        pageNo: param.pageNo,
        column: param.ORDER_COLUMN,
        order:param.ORDER_TYPE
    })
}
export function getDataFromZcfg(param){
    return get("/gsmh_xxtz/gsmhXxtz/list",{
        mhGglx: "2",
        mhGgzt: "0",
        mhGgbt: param.mhGgbt,
        pageSize: param.pageSize,
        pageNo: param.pageNo,
        column: param.ORDER_COLUMN,
        order:param.ORDER_TYPE
    })
}

