import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import MetaInfo from "vue-meta-info";
import './css/global.css'
Vue.config.productionTip = false
Vue.use(VueAxios,axios);
Vue.use(Antd)
Vue.use(MetaInfo);

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount('#app')
