<template>
  <div class="divider">
    <div class="container">
      <a>
        <span style="font-size: 20px;color: rgb(97 155 211);padding-right: 8px">{{data[0]}}</span>
        <span style="font-size: 20px; color: #000000;">{{ data[1] }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Divider",
  props:{
    data:Array
  }
}
</script>

<style scoped>
.divider{
  width: 100%;
  min-width: 1200px;
  height: 20px;
  line-height: 20px;
  margin-top: 35px;
  padding-bottom: 41px;
  background: url("../../../assets/shibian.png") bottom center no-repeat;
  text-align: center;
}
.container{
  width: 1200px;
  height: 100%;
  margin: 0px auto auto auto;
}
</style>
