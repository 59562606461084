<template>
  <div style=" margin-bottom: 10px">
    <a-row style="margin: 15px 0px" type="flex" justify="space-around" align="middle">
      <div style="width: 250px;text-align: center">
        <span><img style="height: 25px;width: 42px" src="@/assets/link.png"></span>
        <span><a target="_blank" href="http://www.samr.gov.cn/">国家市场监督管理局</a></span>
      </div>
      <div style="width: 250px;text-align: center">
        <span><img style="height: 25px;width: 42px" src="@/assets/link.png"></span>
        <span><a target="_blank" href="http://www.sac.gov.cn/">国家标准化管理委员会</a></span>
      </div>
      <div style="width: 250px;text-align: center">
        <span><img style="height: 25px;width: 42px" src="@/assets/link.png"></span>
        <span><a target="_blank" href="https://scjgj.fujian.gov.cn/">福建省市场监督管理局</a></span>
      </div>
      <div style="width: 250px;text-align: center">
        <span><img style="height: 25px;width: 25px" src="@/assets/by.png"></span>
        <span><a target="_blank" href="http://www.fjis.org/ESacGov/index.aspx?bt=c1&NavID=1">福建省标准化研究院</a></span>
      </div>
    </a-row>
    <a-row type="flex" justify="space-around" align="middle">
      <a-col>
        <span>省内市场监督管理局：</span>
        <a-select default-value="请选择" style="width: 240px" @change="handleChange">
          <a-select-option value="请选择">
            请选择
          </a-select-option>
          <a-select-option value="http://scjg.fuzhou.gov.cn/">
            福州市市场监督管理局
          </a-select-option>
          <a-select-option value="https://scjg.xm.gov.cn/">
            厦门市市场监督管理局
          </a-select-option>
          <a-select-option value="http://scjgj.zhangzhou.gov.cn/">
            漳州市市场监督管理局
          </a-select-option>
          <a-select-option value="http://scjgj.quanzhou.gov.cn/">
            泉州市市场监督管理局
          </a-select-option>
          <a-select-option value="http://gsj.sm.gov.cn/">
            三明市市场监督管理局
          </a-select-option>
          <a-select-option value="http://scjgj.putian.gov.cn/">
            莆田市市场监督管理局
          </a-select-option>
          <a-select-option value="http://www.np.gov.cn/cms/html/scjdglj/index/873845818.html">
            南平市市场监督管理局
          </a-select-option>
          <a-select-option value="http://scjgj.longyan.gov.cn/">
            龙岩市市场监督管理局
          </a-select-option>
          <a-select-option value="http://scjd.ningde.gov.cn/">
            宁德市市场监督管理局
          </a-select-option>
          <a-select-option value="http://www.pingtan.gov.cn/">
            平潭综合实验区市场监督管理局
          </a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <span>各地标准化研究机构：</span>
        <a-select default-value="请选择" style="width: 240px" @change="handleChange">
          <a-select-option value="请选择">
            请选择
          </a-select-option>
          <a-select-option value="http://www.cnbis.org/">
            北京市标准化研究院
          </a-select-option>
          <a-select-option value="http://www.hebstd.net.cn/">
            河北省标准化研究院
          </a-select-option>
          <a-select-option value="#">
            山西标准信息网
          </a-select-option>
          <a-select-option value="#">
            内蒙古自治区标准化院
          </a-select-option>
          <a-select-option value="http://www.lnsi.org/">
            辽宁省标准信息公共服务平台
          </a-select-option>
          <a-select-option value="#">
            吉林省标准研究院
          </a-select-option>
          <a-select-option value="http://www.hljis.org.cn/">
            黑龙江省质量与标准化信息网
          </a-select-option>
          <a-select-option value="http://www.jssi.org.cn/">
            江苏省质量和标准化研究院
          </a-select-option>
          <a-select-option value="http://www.zis.org.cn/">
            浙江省标准化研究院
          </a-select-option>
          <a-select-option value="http://www.ahbz.org.cn/">
            安徽省质量和标准化研究院
          </a-select-option>
          <a-select-option value="http://www.fjbz.org.cn/ESacGov/index.aspx?bt=c1&NavID=1">
            福建省标准化研究院
          </a-select-option>
          <a-select-option value="http://www.jxbz.org.cn/">
            江西省标准化研究院
          </a-select-option>
          <a-select-option value="http://www.sdis.org.cn/">
            山东省标准化研究院
          </a-select-option>
          <a-select-option value="#">
            湖北省标准化与质量研究院
          </a-select-option>
          <a-select-option value="http://www.hnis.cn/">
            湖南省标准化研究院
          </a-select-option>
          <a-select-option value="https://www.gdis.org.cn/">
            广东省标准化研究院
          </a-select-option>
          <a-select-option value="#">
            四川省标准文献信息资源服务平台
          </a-select-option>
          <a-select-option value="http://www.ynbz.net/">
            云南标准化研究院
          </a-select-option>
          <a-select-option value="http://www.sqis.com/">
           陕西标准质量信息网
          </a-select-option>
          <a-select-option value="#">
            甘肃省标准化研究院
          </a-select-option>
          <a-select-option value="http://www.gxzl.org.cn/">
            广西壮族自治区标准技术研究院
          </a-select-option>
          <a-select-option value="http://www.nxzl.org.cn/">
            宁夏回族自治区标准化院
          </a-select-option>
          <a-select-option value="#">
            新疆维吾尔自治区标准化研究院
          </a-select-option>
          <a-select-option value="http://amr.xizang.gov.cn/index.htm">
            西藏自治区标准化研究所
          </a-select-option>
          <a-select-option value="http://www.cqis.cn/">
            重庆市质量和标准化研究院
          </a-select-option>
          <a-select-option value="https://www.cnsis.org.cn/">
            上海标准化研究院
          </a-select-option>
          <a-select-option value="#">
            天津市标准化研究院
          </a-select-option>
          <a-select-option value="https://www.sist.org.cn/">
            深圳市标准技术研究院
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "LinkList",
  methods:{
    handleChange(e){
      if (e.search("http")>-1){
        window.open(e);
      }
    }
  }
}
</script>

<style scoped>
a{
  color: #000000
}
a:hover{
  color: #045a8b;
}
</style>
