<template>
  <div id="app" style="width: 100%">
    <a-layout >
      <Header></Header>
      <BigCarousel :lbnr="lbnr" v-if="this.$route.path=='/'"></BigCarousel>
      <a-layout-content id="content">
        <router-view/>
      </a-layout-content>
      <a-layout-footer style="padding: 0px; padding-top: 15px">
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
    <FloatWindow v-if="this.$route.path=='/'"></FloatWindow>
  </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer/footer.vue';
import FloatWindow from "@/components/Home/component/FloatWindow";
import BigCarousel from '@/components/BigCarousel.vue';
import { getDLBT } from "@/apis/homeApi";
export default {
  components:{
    Header,
    Footer,
    FloatWindow,
    BigCarousel
  },
  data(){
    return{
      lbnr:[]
    }
  },
  created(){
    if(this.$route.path=="/"){
      getDLBT().then((res)=>{
        this.lbnr = res.result.records
      })
    }
  },
  watch:{
    $route(route){
      if(route.path=="/"){
        getDLBT().then((res)=>{
          this.lbnr = res.result.records
        })
      }
    }
  }
}
</script>


<style lang="less">
#content{
  margin: 1% 16.5%;
  min-height: 820px;
}
</style>
