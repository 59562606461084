<template>
  <a :title="item.mhGgbt" :href="getUrl(item)">
    <a-card hoverable style="width: 300px">
      <img
          slot="cover"
          :src="URL +item.mhAltp"
      />
      <a-card-meta :title="item.mhGgbt">
        <!-- <template slot="description">
          {{ item.mhGglx==0?"标准制修订":
              item.mhGglx==1?"标准化试点示范":
              item.mhGglx==2?"标准实施效果评估":
              item.mhGglx==3?"标准化+信息化":
              item.mhGglx==4?"标准体系建设":
              item.mhGglx==5?"标准化政策制定":
              item.mhGglx==6?"商品条码服务":
              item.mhGglx==7?"标准化培训":
              item.mhGglx==8?"标识标牌设计":
              item.mhGglx==9?"标准图书销售":
              item.mhGglx==10?"会议及展览服务":"市场监管文化"
          }}
        </template> -->
      </a-card-meta>
    </a-card>
  </a>

</template>

<script>
import {URL} from "@/apis/homeApi";
export default {
  name: "PicCard",
  data(){
    return{
      URL: URL
    }
  },
  props:{
    item:Object
  },
  methods:{
    getUrl(param){
      var id  = param.id
      var type = param.mhGglx
      var basePath = ""
      switch (type){
        case "0":
          basePath = "/zyyw/bzzxd/";break;
        case "1":
          basePath = "/zyyw/bzhsdsf/";break;
        case "2":
          basePath = "/zyyw/bzssxgpg/";break;
        case "3":
          basePath = "/zyyw/bzhxxh/";break;
        case "4":
          basePath = "/zyyw/bztxjs/";break;
        case "5":
          basePath = "/zyyw/bzhzczd/";break;
        case "6":
          basePath = "/zyyw/sptmfw/";break;
        case "7":
          basePath = "/zyyw/bzhpx/";break;
        case "8":
          basePath = "/zyyw/bsbpsj/";break;
        case "9":
          basePath = "/zyyw/bztsxs/";break;
        case "10":
          basePath = "/zyyw/hyjzlfw/";break;
        default:
          basePath = "/zyyw/scjgwh/";
      }
      return basePath +"richText/id="+id
    }
  }
}
</script>

<style scoped>

</style>
