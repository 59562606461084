<template>
  <a-carousel arrows autoplay>
      <div
          slot="prevArrow"
          style="left: 10px;zIndex: 1;font-size: 25px;color: #fff;"
     >
       <a-icon type="left-circle" />
     </div>
     <div slot="nextArrow" style="right: 10px;font-size: 25px;color: #fff;">
       <a-icon type="right-circle" />
    </div>
    <div v-for="(item,index) in lbnr" :key="index">
      <a :href="item.basePath"><img class="img" :src="URL + item.mhLbtp"></a>
      <div class="wrapper">
        <p class="text" :style="{'width':screenWidth<=1790?'350px':'420px'}">{{ item.mhGgbt }}</p>
      </div>
    </div>
  </a-carousel>
</template>

<script>
import {URL} from "../../../apis/homeApi";
export default {
  name: "Carousel",
  data(){
    return{
      URL:URL
    }
  },
  props: {
    lbnr:Array,
    screenWidth: Number
  },
  methods:{
    // getUrl(param){
    //   var bathUrl = ""
    //   if(param.mhGglx_dictText=="标准制修订"){
    //     bathUrl = "/zyyw/bzzxd/richText/"
    //   }else if(param.mhGglx_dictText=="标准化试点示范"){
    //     bathUrl = "/zyyw/bzhsdsf/richText/"
    //   }else if(param.mhGglx_dictText=="标准实施效果评估"){
    //     bathUrl = "/zyyw/bzssxgpg/richText/"
    //   }else if(param.mhGglx_dictText=="标准化+信息化"){
    //     bathUrl = "/infoCenter/tzgg/richText/"
    //   }else if(param.mhGglx_dictText=="标准体系建设"){
    //     bathUrl = "/zyyw/bztxjs/richText/"
    //   }else if(param.mhGglx_dictText=="标准化政策制定"){
    //     bathUrl = "/zyyw/bzhzczd/richText/"
    //   }else if(param.mhGglx_dictText=="商品条码服务"){
    //     bathUrl = "/zyyw/sptmfw/richText/"
    //   }else if(param.mhGglx_dictText=="标准化培训"){
    //     bathUrl = "/zyyw/bzhpx/richText/"
    //   }else if(param.mhGglx_dictText=="标识标牌设计"){
    //     bathUrl = "/zyyw/bsbpsj/richText/"
    //   }else if(param.mhGglx_dictText=="标准图书销售"){
    //     bathUrl = "/zyyw/bztsxs/richText/"
    //   }else if(param.mhGglx_dictText=="会议及展览服务"){
    //     bathUrl = "/zyyw/hyjzlfw/richText/"
    //   }else if(param.mhGglx_dictText=="市场监管文化"){
    //     bathUrl = "/zyyw/scjgwh/richText/"
    //   }else if(param.mhGglx=="0"){
    //     bathUrl = "/infoCenter/tzgg/richText/"
    //   }else if(param.mhGglx_dictText=="通知公告"){
    //     bathUrl = "/infoCenter/tzgg/richText/"
    //   }else if(param.mhGglx_dictText=="新闻动态" ){
    //     bathUrl = "/infoCenter/xwdt/richText/"
    //   }else if(param.mhGglx_dictText=="政策法规"){
    //     bathUrl = "/infoCenter/zcfg/richText/"
    //   }else{
    //     bathUrl = "/infoCenter/xwdt/richText/"
    //   }
    //   return bathUrl +"id="+param.id
    // }
  }
}
</script>

<style scoped >

.ant-carousel .slick-prev::before {
    content: '';
}
.ant-carousel .slick-next::before {
    content: '';
}
.ant-carousel >>>  .slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    margin: 0;
    padding: 0;
    text-align: right;
    list-style: none;
}
.ant-carousel >>> .slick-dots li.slick-active button {
    width: 24px;
    background: #1890ff;
    opacity: 1;
}
.ant-carousel >>> .slick-slide {
  height: auto;
  line-height: 383.8px;
  background: rgb(240 242 245);
  overflow: hidden;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-slide .wrapper{
  max-height: 30px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: #333030;
  line-height: 30px;
  padding-left: 8px;
}
.ant-carousel >>> .slick-slide .wrapper .text{
  color: rgb(210, 205, 205);
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.ant-carousel >>> .slick-slide .img{
  display: inline-block;
  height: 383.8px;
  width: 100%;
}


</style>
