<template>
  <a-carousel style="min-height:350px;" autoplay  effect="fade">
    <div
        slot="prevArrow"
        style="left: 10px;zIndex: 1;font-size: 25px;color: #ccc;"
    >
      <a-icon type="left-circle" />
    </div>
    <div slot="nextArrow" style="right: 10px;font-size: 25px;color: #ccc;">
      <a-icon type="right-circle" />
    </div>
    <div v-for="(item,index) in lbnr" :key="index" class="banner">
      <a :target="getTarget(item.mhUrl)" :href="getHref(item.mhUrl)"><img class="img" :src="URL + item.mhDlbt"></a>
    </div>
  </a-carousel>
</template>

<script>
import {URL} from "../apis/homeApi";
export default {
  name: "BigCarousel",
  data(){
    return{
      URL:URL
    }
  },
  props: {
    lbnr:Array
  },
  methods:{
    getTarget(url) {
      // 使用 JavaScript 逻辑判断
      return (url||'').trim() === '' ? '' : '_blank'; // '_self' 表示在当前窗口打开
    },
    getHref(url){
      return (url||'').trim() ===''? 'javascript:void(0)':url;
    }
  }
}
</script>

<style scoped>
.ant-carousel .slick-prev::before {
  content: '';
}
.ant-carousel .slick-next::before {
  content: '';
}

.ant-carousel >>> .slick-dots li.slick-active button {
  width: 24px;
  background: #1890ff;
  opacity: 1;
}
.ant-carousel >>> .slick-slide {
  /*height: auto;*/
  /*line-height: 383.8px;*/
  /*background: rgb(240 242 245);*/
  /*overflow: hidden;*/
}
.ant-carousel >>> .slick-slide .img{
  /* height: 500px; */
  width: 100%; 
}
</style>
