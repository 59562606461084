<template>
  <!-- 2023/10/31综合服务部要求关闭商品条码服务与市场监管文化 -->
  <a :href="(item.mhSh != 11 && item.mhSh != 6)? item.mhUrl : 'javascript:void(0)'">
    <a-card hoverable style="width: 283px;min-height:143px;background: rgba(0,0,0,0);border:none;">
      <img
        v-if="item.mhSh != 11 && item.mhSh != 6"
          style="width:100%"
          slot="cover"
          alt="example"
          :src="URL + item.mhTp"
      />
    </a-card>
  </a>
</template>

<script>
import {URL} from "../../../apis/homeApi";
export default {
  name: "SingleCard",
  data(){
    return{
      URL:URL
    }
  },
  props:{
    item: Object
  }
}
</script>

<style scoped>

</style>
