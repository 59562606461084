import {get} from "../utils/request";


export function getDataFromZyyw(param){
    return get("/gsmh_zyyw/gsmhZzyw/list",{
        mhGgbt: param.mhGgbt,
        mhGglx: param.mhGglx,
        mhGgzt: "0",
        pageSize: param.pageSize,
        pageNo: param.pageNo,
        column: param.ORDER_COLUMN,
        order:param.ORDER_TYPE
    })
}
