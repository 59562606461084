<template>
  <a-card
      style="width:100%;height: 383.8px"
      :tab-list="tabListNoTitle"
      :active-tab-key="noTitleKey"
      @tabChange="key => onTabChange(key)"
  >
    <template v-if="info.length>0">
      <div  class="content" v-for="(item,index) in info" :key="index">
        <a-tag v-show="item.mhSfzd==1" class="title" style="margin-top:12px" color="rgb(3 93 145)">置顶</a-tag>
        <div class="title" :style="{'max-width':screenWidth<=1790?'420px':'500px'}"><a style="color: #424141" :href="getUrl(item.id)">{{ item.mhGgbt }}</a></div>
        <div class="footer">{{ item.createTime.split(" ")[0] }}</div>
      </div>
    </template>
    <a-empty  description="暂无数据" v-else />
    <a slot="tabBarExtraContent" :href="this['noTitleKey']">更多</a>
  </a-card>
</template>

<script>
export default {
  name: "SingleCard",
  props: {
    info:Array,
    screenWidth: Number
  },
  data(){
    return{
      noTitleKey: 'xwdt',
      tabListNoTitle: [
        {
          key: 'xwdt',
          tab: '新闻动态',
        },
        {
          key: 'tzgg',
          tab: '通知公告',
        },
        {
          key: 'zcfg',
          tab: '政策法规',
        },
      ],
    }
  },
  methods:{
    onTabChange(key) {
      this["noTitleKey"] = key;
      this.$emit('getDataByMenu', key)
    },
    getUrl(param){
      var bathUrl = ""
      if (this["noTitleKey"]=="xwdt"){
        bathUrl = "/infoCenter/xwdt/richText/"
      }else if(this["noTitleKey"]=="zcfg"){
        bathUrl = "/infoCenter/zcfg/richText/"
      }else {
        bathUrl = "/infoCenter/tzgg/richText/"
      }
      return bathUrl +"id="+param
    }
  }
}
</script>

<style scoped>
.ant-card >>> .ant-card-head{
  text-align: left;
}
.ant-card >>> .ant-card-head a{

}
.content{
  padding-bottom: 3px;
  padding-left: 2px;
  border-bottom: solid 1px #cccccc63;
  height: 45px;
  line-height: 45px;
}
.title{
  display: inline;
  float: left;
  max-width: 500px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.footer{
  display: inline;
  float: right;
  font-size: 12px;
}
.ant-tabs-nav .ant-tabs-tab-active {
    color: #236ea1;
    font-weight: 500;
}
</style>
