import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import List from '../components/InfoList/InfoList.vue'
import RichText from '../views/DetaiInfoPage.vue'
import InfoListPage from '../views/InfoListPage.vue'
// import MainBusinessPage from "../views/MainBusinessPage.vue";
// import MainBusinessList from '../components/MainBusinuss/InfoList.vue'
// import PartyBuildingCenterPage from "../views/PartyBuildingCenter.vue";
// import PartyBuildingCenterList from '../components/PartyBuildingCenter/InfoList.vue'
// import AboutUsPage from "../views/AboutUs.vue";
Vue.use(VueRouter)

const routes = [
    // {
    //     path:'/',
    //     redirect:'/home'
    // },
    {
        path:'/',
        meta:{title:'新闻动态'},
        component: HomePage,
    },
    {
        path: '/infoCenter',
        component: InfoListPage,
        meta:{title:'信息中心'},
        children:[{
            path: '/xwdt',
            component: List,
            meta:{title:'新闻动态'},
            children:[]
        },{
            path: '/tzgg',
            component: List,
            name: 'TZGG',
            meta:{title:'通知公告'}
        },{
            path: '/zcfg',
            component: List,
            name: 'ZCFG',
            meta:{title:'政策法规'}
        },{
            path: '/xwdtRichText/:id',
            alias:'/infoCenter/xwdt/richText/:id',
            component: RichText,
            meta:{title:'新闻动态 > 详情内容'}
        },{
            path: '/tzggRichText/:id',
            alias:'/infoCenter/tzgg/richText/:id',
            component: RichText,
            meta:{title:'通知公告 > 详情内容'}
        },{
            path: '/zcfgRichText/:id',
            alias:'/infoCenter/zcfg/richText/:id',
            component: RichText,
            meta:{title:'政策法规 > 详情内容'}
        }]
    },
    {
        path: '/zyyw',
        name: 'ZYYW',
        component: InfoListPage,
        meta:{title:'主营业务'},
        children:[{
            path: '/bzzxd',
            component: List,
            name:'bzzxd',
            meta:{title:'标准制修订'}
        },{
            path: '/bzzxdRichText/:id',
            component: RichText,
            alias:'/zyyw/bzzxd/richText/:id',
            meta:{title:'标准制修订 > 详情内容'}
        },{
            path: '/bzhsdsf',
            component: List,
            name: 'bzhsdsf',
            meta:{title:'标准化试点示范'},
        },{
            path: '/bzhsdsfRichText/:id',
            alias:'/zyyw/bzhsdsf/richText/:id',
            component: RichText,
            meta:{title:'标准化试点示范 > 详情内容'}
        },{
            path: '/bzssxgpg',
            component: List,
            name: 'bzssxgpg',
            meta:{title:'标准实施效果评估'}
        },{
            path: '/bzssxgpgRichText/:id',
            alias:'/zyyw/bzssxgpg/richText/:id',
            component: RichText,
            meta:{title:'标准实施效果评估 > 详情内容'}
        },{
            path: '/bzhxxh',
            component: List,
            name: 'bzhxxh',
            meta:{title:'标准化+信息化'}
        },{
            path: '/bzhxxhRichText/:id',
            alias:'/zyyw/bzhxxh/richText/:id',
            component: RichText,
            meta:{title:'标准化+信息化 > 详情内容'}
        },{
            path: '/bztxjs',
            component: List,
            name: 'bztxjs',
            meta:{title:'标准体系建设'}
        },{
            path: '/bztxjsRichText/:id',
            alias:'/zyyw/bztxjs/richText/:id',
            component: RichText,
            meta:{title:'标准体系建设 > 详情内容'}
        },{
            path: '/bzhzczd',
            component: List,
            name: 'bzhzczd',
            meta:{title:'标准化政策制定'}
        },{
            path: '/bzhzczdRichText/:id',
            alias:'/zyyw/bzhzczd/richText/:id',
            component: RichText,
            meta:{title:'标准化政策制定 > 详情内容'}
        },{
            path: '/sptmfw',
            component: List,
            name: 'sptmfw',
            meta:{title:'商品条码服务'}
        },{
            path: '/sptmfwRichText/:id',
            alias:'/zyyw/sptmfw/richText/:id',
            component: RichText,
            meta:{title:'商品条码服务 > 详情内容'}
        },{
            path: '/bzhpx',
            component: List,
            meta:{title:'标准化培训'}
        },{
            path: '/bzhpxRichText/:id',
            alias:'/zyyw/bzhpx/richText/:id',
            component: RichText,
            meta:{title:'标准化培训 > 详情内容'}
        },{
            path: '/bsbpsj',
            component: List,
            name: 'bsbpsj',
            meta:{title:'标识标牌设计'}
        },{
            path: '/bsbpsjRichText/:id',
            alias:'/zyyw/bsbpsj/richText/:id',
            component: RichText,
            meta:{title:'标识标牌设计 > 详情内容'}
        },{
            path: '/bztsxs',
            component: List,
            name: 'bztsxs',
            meta:{title:'标准图书销售'}
        },{
            path: '/bztsxsRichText/:id',
            alias:'/zyyw/bztsxs/richText/:id',
            component: RichText,
            meta:{title:'标准图书销售 > 详情内容'}
        },{
            path: '/hyjzlfw',
            component: List,
            name: 'hyjzlfw',
            meta:{title:'会议及展览服务'}
        },{
            path: '/hyjzlfwRichText/:id',
            alias:'/zyyw/hyjzlfw/richText/:id',
            component: RichText,
            meta:{title:'会议及展览服务 > 详情内容'}
        },{
            path: '/scjgwh',
            component: List,
            name: 'scjgwh',
            meta:{title:'市场监管文化'}
        },{
            path: '/scjgwhRichText/:id',
            alias:'/zyyw/scjgwh/richText/:id',
            component: RichText,
            meta:{title:'市场监管文化 > 详情内容'}
        }]
    },
    {
        path: '/djzx',
        component: InfoListPage,
        meta:{title:'党建中心'},
        children:[{
            path: '/djgz',
            component: List,
            meta:{title:'公司党支部'}
        },{
            path: '/djgzRichText/:id',
            alias:'/djzx/djgz/richText/:id',
            component: RichText,
            meta:{title:'公司党支部 > 详情内容'}
        }]
    },
    {
        path: '/gywm',
        name: 'gywm',
        component: InfoListPage,
        meta:{title:'关于我们'},
        children:[{
            path: '/fjbyxxgs',
            component: List,
            meta:{title:'福建省标院信息技术有限公司'},
        },{
            path: '/fjxbzwhgs',
            component: List,
            meta:{title:'福建省新标准文化发展有限公司'},
        }]

    }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
