<template>
  <a-list style="background: #ffff; padding: 15px;" item-layout="horizontal"  :pagination="pagination" :data-source="result.records">
    <div slot="header">
      <b>信息列表</b>
    </div>
    <a-list-item slot="renderItem" key="item.title" slot-scope="item">
      <a-tag v-show="item.mhSfzd==1" color="rgb(3 93 145)">置顶</a-tag>
      <a-list-item-meta>
        <a slot="title" :href="getUrl(item.id)">{{ item.mhGgbt }}</a>
      </a-list-item-meta>
      <div>{{ item.createTime.split(" ")[0] }}</div>
    </a-list-item>
  </a-list>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      current: 0,
      total: 0,
    };
  },
  watch:{
    result:function(newData){
      this.total = newData.total;
      this.current = newData.current;
    }
  },
  computed:{
    pagination(){
      return{
        onChange: page => {
          this.$emit("getDataByUrl",{
            pageNo: page,
            pageSize: 10,
            path: this.$route.path
          })
        },
        pageSize: 10,
        total: this.total,
        current: this.current
      }
    }
  },
  props:{
    result: Object
  },
  methods:{
    getUrl(param){
      return this.$route.matched[0].path+this.$route.matched[1].path +"/richText/id="+param
    }
  }
};

</script>

<style scoped>

</style>
