<template>
  <div>
    <img :src="imgUrl" style="width: 100%;margin-top: -15px;margin-bottom: 15px;">
    <BreadCrumb></BreadCrumb>
    <router-view :key="$route.path"/>
  </div>
</template>

<script>

import BreadCrumb from "@/components/BreadCrumb.vue";
export default {
  name: "InfoListPage",
  components:{
    BreadCrumb
  },
  data(){
    return{
      imgUrl:""
    }
  },
  mounted(){
    let matchPath = this.$route.matched[0].path
    if(matchPath=="/infoCenter"){
        // 信息中心
        this.imgUrl = '/img/xxzx.05937f43.jpg'
      }else if(matchPath=="/zyyw"){
        // 主营业务
        this.imgUrl = '/img/zyyw.c3880891.jpg'
      }else if(matchPath=="/djzx"){
        // 党建中心
        this.imgUrl = '/img/djzx.ec937d2a.jpg'
      }else if(matchPath=="/gywm"){
        // 关于我们
        this.imgUrl = '/img/gywm.1d786286.jpg'
      }
  },
  beforeUpdate(){
    let matchPath = this.$route.matched[0].path
    if(matchPath=="/infoCenter"){
        // 信息中心
        this.imgUrl = '/img/xxzx.05937f43.jpg'
      }else if(matchPath=="/zyyw"){
        // 主营业务
        this.imgUrl = '/img/zyyw.c3880891.jpg'
      }else if(matchPath=="/djzx"){
        // 党建中心
        this.imgUrl = '/img/djzx.ec937d2a.jpg'
      }else if(matchPath=="/gywm"){
        // 关于我们
        this.imgUrl = '/img/gywm.1d786286.jpg'
      }
  
  }
}
</script>

<style scoped>

</style>