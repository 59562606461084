<template >
  <div id="wrapper" style="background: #fff;box-shadow: 0px -20px 30px #045a8b;">
    <a-layout-header id="header" >
<!--      <a-row>-->
<!--        <a-col>-->
<!--          <img src="@/assets/henfu.jpg" style="width: 100%">-->
<!--        </a-col>-->
<!--      </a-row>-->
      <a-row >
        <a-col style="height:104px;line-height: 104px;text-align: left" :span="10">
          <img src="@/assets/logo0903_proc.jpg">
        </a-col>
        <a-col :span="14">
          <a-menu :selectedKeys="[$route.path]" @click="handleClick" mode="horizontal" style="height: 104px;line-height: 104px;float: right">
            <template v-for="item in HomeMenuList">
              <a-menu-item v-if="!item.children " :key="item.path"> {{ item.title }} </a-menu-item>
              <a-sub-menu v-else :key="item.key" popupClassName="selfSubClassName">
                  <span slot="title" class="submenu-title-wrapper"
                  >{{ item.title }}<a-icon style="margin-left: 5px" type="down" /></span>
                <a-menu-item v-for="subItem in item.children" :key="subItem.path" class="submenu-title-wrapper-sub">
                  {{ subItem.title }}
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-menu>
        </a-col>
      </a-row>
    </a-layout-header>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  data(){
    return{
      HomeMenuList: []
    }
  },
  methods:{
    handleClick(e){
      this.$router.push(e.key)
    }
  },
  mounted() {
    this.axios.get('/api/HomeMenu').then((response)=>{
      this.HomeMenuList = response.data.data
    }).catch((response)=>{
      console.log(response);
    })
  },
  
}
</script>

<style scoped>
#header {
  margin: 0% 16.5%;
  background: #fff;
  padding: 0;
}
/*.ant-menu-item {*/
/* width: 90px;*/
/* height: 26px !important;*/
/* line-height: 26px !important;*/
/* margin: 0 !important;*/
/* padding: 0 8px !important;*/
/*}*/

.ant-menu-item-active {
 background-color: rgba(35, 110, 161, 0) !important;
 color: rgb(38 150 255) !important;
}
.ant-menu-item-selected{
  background-color: rgba(35, 110, 161, 0) !important;
  color:rgb(38 150 255) !important;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: rgb(38 150 255);
  border-bottom: 2px solid rgb(2 176 240);


}

.ant-menu-submenu-horizontal .ant-menu-submenu-open .ant-menu-submenu-active {
  color: rgba(35, 110, 161, 0.99);
  border-bottom: 2px solid rgb(2 176 240);
}

.ant-menu-submenu > .ant-menu {
 width: 100px;
 min-width: 100px;
}

.ant-menu-item,
.submenu-title-wrapper{
  font-size: 17px;
  font-weight: 550;
}
.submenu-title-wrapper-sub{
  font-size: 15px !important;
  font-weight: normal !important;
}
</style>
